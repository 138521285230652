import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const gsm1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Egy új online áruház tervezése és felépítésére volt szüksége a régóta működő cégnek, mert a korábbi sablonwebhelye már nem felel meg a fejlődő üzleti folyamatainak és a márkája színvonalának. Olyan online áruházra van szükségük, amely tükrözi az általuk képviselt minőséget.",
  displayXo: true,
  reverse: false,
  title: "Sablonból egyedibe",
  picture: "/images/refs/gsm/gsm_1.png",
  boldDescription: ''
}

const gsm2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Az értékesítés megszakítása nélküli munkavégzés érdekében először egy egyedi admin felületet építettünk, ahová még a weboldal kezelőfelületének programozása közben is tölthettek fel termékeket és tartalmakat. Ezért a webáruház azonnal elindult, amint elkészül a reszponzív megjelenés.",
  displayXo: true,
  reverse: true,
  title: "Folyamatos átállás",
  picture: "/images/refs/gsm/gsm_2.png",
  boldDescription: ''
}

export const gsmInfoCards = [
  gsm1,
  gsm2,
]

