import { Reference } from "../reference-interface"
import { gsmInfoCards } from "../../service-info-card-interface/used_by/gsm-centrum-cards"

export const GsmCentrum : Reference = {
  monitorPic: "/images/refs/gsm/gsm_monitor.png",
  siteName: "GSM Centrum webshop",
  siteDescription: "Az üzelti fejlődésük megtartása miatt új webáruház mellett döntöttek, ami még komolyabb részletességgel mégis egyszerű kezelhetőséggel és átláthatóságával mutatja be az általuk forgalmazott elektronikus termékeket.",
  siteUrl: "https://www.gsmcentrum.hu/",
  cards: gsmInfoCards,
  referencePics: [
    "/images/refs/gsm/gsm_ref.png",
    "/images/refs/gsm/gsm_ref_color.png",
    "/images/refs/gsm/gsm_3.png",
  ],
  pageFunctions: [
    "Partner ERP",
    "Egyedi design",
    "Önálló adminfelület",
    "Egyedi partner árak"
  ],
  reversePageFunctions: false,
}
